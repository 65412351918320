
import * as React from 'react';
import { alpha } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

export default function Hero() {
    return (
        <Box
            id="hero"
            sx={(theme) => ({
                width: '100%',
                backgroundImage:
                    theme.palette.mode === 'light'
                        ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
                        : `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,
                backgroundSize: '100% 20%',
                backgroundRepeat: 'no-repeat',
            })}
        >
            <Container
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    pt: { xs: 14, sm: 20 },
                    pb: { xs: 8, sm: 12 },
                }}
            >
                <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%' } }}>
                    <Typography
                        variant="h1"
                        sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', md: 'row' },
                            alignSelf: 'center',
                            textAlign: 'center',
                            fontSize: 'clamp(3.5rem, 10vw, 4rem)',
                        }}
                    >
                       Manage Fiat &&nbsp;
                        <Typography
                            component="span"
                            variant="h1"
                            sx={{
                                fontSize: 'clamp(3rem, 10vw, 4rem)',
                                color: (theme) =>
                                    theme.palette.mode === 'light' ? '#096dff' : 'primary.light',
                            }}
                        >
                            Crypto
                        </Typography>
                    </Typography>
                    <Typography
                        textAlign="center"
                        color="text.secondary"
                        sx={{ alignSelf: 'center', width: { sm: '100%', md: '80%' } }}
                    >
                        Combining the functionalities of a traditional bank and a cryptocurrency exchange, offering you a seamless, secure, and integrated financial experience. 
                    </Typography>
                    <Typography variant="caption" textAlign="center" sx={{ opacity: 0.8, fontWeight: 900 }}>
                        Development In Progress
                        ....
                    </Typography>
                    <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        alignSelf="center"
                        spacing={1}
                        useFlexGap
                        sx={{ pt: 2, width: { xs: '100%', sm: 'auto' } }}
                    >
                       
                        <Button variant="contained" color="primary" sx={{ backgroundColor: "#096dff", color: "white" }} href="https://wa.me/message/KNRAAIAW46KEB1">
                            Trade Cryptocurrencies now
                        </Button>
                     
                    </Stack>
                    <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        alignSelf="center"
                     
                        useFlexGap
                        sx={{  width: { xs: '100%', sm: 'auto' } }}
                    >
                    <Typography variant="caption" textAlign="center" sx={{ opacity: 0.8, fontSize: 19, fontWeight: 500 }}>
                        or Whatsapp
                    </Typography>
                        <Typography variant="caption" textAlign="center" sx={{ opacity: 0.8, fontSize: 19, fontWeight: 600 }}>
                           &nbsp;+2348148247237
                        </Typography>
                    </Stack>
                   
                </Stack>
                <Box
                    id="image"
                    sx={(theme) => ({
                        mt: { xs: 8, sm: 10 },
                        alignSelf: 'center',
                        height: { xs: 500, sm: 600, },
                        width: { xs: "100%", sm: "40%", },
                     
                        backgroundImage:
                            theme.palette.mode === 'light'
                                ? 'url("https://firebasestorage.googleapis.com/v0/b/groupchat-d6de7.appspot.com/o/Beige%20Elegant%20New%20Collection%20Instagram%20Post.png?alt=media&token=3b66283f-3e64-4f28-a194-0044bf7fd5ba")'
                                : 'url("https://firebasestorage.googleapis.com/v0/b/groupchat-d6de7.appspot.com/o/Beige%20Elegant%20New%20Collection%20Instagram%20Post.png?alt=media&token=3b66283f-3e64-4f28-a194-0044bf7fd5ba")',
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        borderRadius: '10px',
                        outline: '1px solid',
                        outlineColor:
                            theme.palette.mode === 'light'
                                ? alpha('#BFCCD9', 0.5)
                                : alpha('#9CCCFC', 0.1),
                        boxShadow:
                            theme.palette.mode === 'light'
                                ? `0 0 12px 8px ${alpha('#9CCCFC', 0.2)}`
                                : `0 0 24px 12px ${alpha('#033363', 0.2)}`,
                    })}
                />
            </Container>
        </Box>
    );
}
